module.exports = {
    TYPE_OPTIONS: [
        {text: "Entry Test", value: 1},
        {text: "Breaking Test", value: 2}
    ],
    vocabDefault: {
        words: [],
        game_type: 1,
        match_duration: 6
    },
    listeningDefault: {
        question: {
            title: "",
            type: 1,
            time: null,
            answers: [],
            questions: [],
            correct_answer_trans: { type: 'translatable-text', translations: [] },
            answer_explain: { type: 'translatable-text', translations: [] },
        },
        conversations: [],
        context: [],
    },
    interactionDefault:{
        is_suffle: false,
        context: [],
        questions: []
    },
    pronunciationDefault: {
        
    },
    partDefault: {
        title: { type: 'translatable-text', translations: [] },
        levels: [],
        type: null,
        guide_content: {
            type: 'translatable-components',
            meta: {
                default_lang: 'vi'
            },
            content: [],
            translations: [
                {
                language: 'vi',
                content: []
                }
            ]
        },
        guide_image: {
            value: null,
            type: "image",
            meta: {
                alt: null,
                redirect_link: null,
            },
        }
    },
}